<template>
  <section class="layout-header">
    <section
      class="left"
      @click="onClick"
    >
      <!-- <span class="title">{{ title }}</span> -->
    </section>
    <section class="center">
      <AppMenu
        :menus="headerMenus"
        :props="menuProps"
      />
    </section>
    <section class="right">
    </section>
  </section>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import AppMenu from './common/menu.vue'
import useApp from '@/hooks/state/use-app'
import {cloneDeep} from 'lodash-es'

// hooks
const router = useRouter()
const { headerMenus } = useApp()
// state
const menuProps = ref<any>({
  mode: 'horizontal',
  ellipsis: false,
  router: true
})

// fns
function onClick () {
  router.push({ path: '/' })
}
</script>
<style lang="scss" scoped>
.layout-header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $primary-bg-header;

  .left {
    flex: 1;
  }

  .right {
    flex: 1;
  }

  .left {
    .title {
      font-weight: 700;
      font-size: 20px;
      color: $primary-color;
    }

    img {
      width: 28px;
      cursor: pointer;
    }
  }

  .center {
    width: $page-main-center-width;
    text-align: center;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .item {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 5px;

      img {
        width: 20px;
      }

      &.control {
        cursor: pointer;
      }
    }
  }
}
</style>
