<template>
  <div class="zhuanjia_fixed">
    <img
      src="/static/images/home/xinzheng.png"
      @click="openDetail"
    >
  </div>
</template>
<script setup lang="ts">
const apiPrefix = import.meta.env.VITE_ENV === 'prod' ? '/yuhangplatform' : ''

function openDetail () {
  window.open('https://mp.weixin.qq.com/s/eVY4WB2U-qnX1v1lxdW1GQ')
  
}
</script>

<style scoped lang="scss">
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(6deg);
  }
  20% {
    transform: rotate(-6deg);
  }
  30% {
    transform: rotate(6deg);
  }
  40% {
    transform: rotate(-6deg);
  }
  50% {
    transform: rotate(6deg);
  }
  60%,
  100% {
    transform: rotate(0deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.zhuanjia_fixed {
  position: fixed;
  right: 12px;
  bottom: calc(20% + 240px);
  z-index: 999;
  animation: scale 2s infinite;

  img {
    cursor: pointer;
    width: 197px;
    height: 94px;
  }
  .text{
    position: absolute;
    top: 55%;
    left: 50%;
    max-width: 120px;
    transform: translateX(-50%);
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 21px;
    padding:2px 4px;
    background: rgba(255,255,255,0.52);
    border-radius: 4px;
    color:#507BB8;
    border: 1px solid #FFFFFF;
  }
}
</style>
